import React from 'react'
import CV from '../../assets/CV.pdf'

const CTA = () => {
  return (
    <div className='header--offerBlock'>
        <a href={CV} download ><span>Download CV</span></a>
        <a href='#contact'><span>Let's Talk</span></a>
    </div>
  )
}

export default CTA