import React from 'react'
import './footer.scss'

const Footer = () => {
  return (
    <footer>
      <div className="container">        
          <p>© 2023 by Stanislav Kyrylchuk</p>       
      </div>
    </footer>

  )
}

export default Footer