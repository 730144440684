import React from 'react'
import { useTypewriter, Cursor } from 'react-simple-typewriter'
import { motion } from "framer-motion"

import CTA from './CTA'
import ME from '../../assets/about_me_1.png'
import HeaderSocials from './HeaderSocials'

import './header.scss'


const Header = () => {
  const [text, count] = useTypewriter({
		words: [
		'Hi, My Name is Stanislav',
		'TheGuyWhoLovesTea.jsx',
		"<ButLovesToCodeMore />",
    ],
		loop: true,
		delaySpeed: 2000,
  })
  return (
    <header className="section-component">
      <div className="container container--header">
        
        <h5 className="text-shadow">Front-end | Full-stack developer</h5>
        <h1 className=''>
          <span className="mr-3">{text}</span>
					<Cursor cursorColor='#4db5ff'/>
        </h1>
        
        <CTA />
        
        <HeaderSocials />
        <motion.div 
          initial={{
            y: 200,
            opacity: 0,
          }}
          whileInView={{
            y: 0,
            opacity: 1,
          }}
          transition={{
            duration: 1.3,
          }}
          viewport={{
            once: true,
          }}
          className="header--me" 
          >
          <img src={ME} alt="Stanislav Kyrylchuk"/>
        </motion.div>

        <a href="#contact" className="scroll-down">Scroll Down</a>
      </div>
    </header>
  )
}

export default Header