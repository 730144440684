import React from "react";
import "./about.scss";
import { motion } from "framer-motion";
import ME from "../../assets/author_2.jpg";
import ARTICLE_CAPTION from "../../assets/article_caption.png";
import { BsFillBookmarkStarFill } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";

const About = () => {
  return (
    <section id="about">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
        viewport={{
          once: true,
        }}
        className="section-component"
      >
        <h5>Get to Know</h5>
        <h2>About me</h2>
        <div className="container container--about">
          <motion.div
            initial={{
              x: -200,
              opacity: 0,
            }}
            whileInView={{
              x: 0,
              opacity: 1,
            }}
            transition={{
              duration: 1.3,
            }}
            viewport={{
              once: true,
            }}
            className="about--me"
          >
            <div className="about--me-image">
              <img src={ME} alt="About me" />
            </div>
          </motion.div>
          <div className="about--content">
            <div className="about--content-cards">
              <article className="about--content-card">
                <BsFillBookmarkStarFill className="about--icon" />
                <h5>Experience</h5>
                <small>5+ Years Working</small>
              </article>
              <article className="about--content-card">
                <FiUsers className="about--icon" />
                <h5>Clients</h5>
                <small>40+ Clients Worldwide</small>
              </article>
              <article className="about--content-card">
                <VscFolderLibrary className="about--icon" />
                <h5>Projects</h5>
                <small>20+ Created Projects</small>
              </article>
            </div>
            <p>
              I constantly develop myself, this applies to absolutely
              everything, including web development. I always finish the job and
              try to achieve the best result. I'd been working a lot of times
              directly with the clients, and with some I still have a strong
              partnership. My main specialization in web development is
              front-end. Also, I speak fluently in several languages, so there
              are no borders for me in communication.
            </p>
            <p>
              I started to learn web development by myself. The way of
              self-development and working with different technologies led me to
              a Full-stack developer. So far I have an experience with
              JavaScript, PHP, C# and Python. The full list of my skills are
              located below, check it out!
            </p>
            <a href="#contact" className="btn btn-primary">
              Let's Talk
            </a>
          </div>
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
        viewport={{
          once: true,
        }}
        className="section-component"
      >
        <h2>Interview with me</h2>
        <div className="container container--about">
          <div className="about--content">
            <p>
              <b style={{ color: "rgba(255, 255, 255, 1)" }}>
                🚀 Haven't heard about my latest interview?
              </b>{" "}
              Here's an article in Polish on monolithic applications, discussing
              the pros and cons of dividing them into separate back-end and
              front-end applications, among other topics! Check it out by
              clicking the button below!
            </p>
            <a
              href="https://geek.justjoin.it/dlaczego-firmy-rezygnuja-z-aplikacji-monolitycznych-wywiad/"
              className="btn btn-primary"
            >
              Read an article!
            </a>
          </div>
          <div className="about--article-caption">
            <img src={ARTICLE_CAPTION} alt="article caption" />
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default About;
