import React from "react";
import { BsPatchCheckFill } from "react-icons/bs";
import { motion } from "framer-motion";
import "./experience.scss";

const Experience = () => {
  return (
    <section id="experience">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
        viewport={{
          once: true,
        }}
        className="section-component"
      >
        <div className="container">
          <h5>What Skills I Have</h5>
          <h2>My Experience</h2>
          <div className="container--experience">
            <motion.div
              initial={{
                x: -200,
                opacity: 0,
              }}
              whileInView={{
                x: 0,
                opacity: 1,
              }}
              transition={{
                duration: 1.5,
              }}
              viewport={{
                once: true,
              }}
              className="experience--frontend"
            >
              <h3>Front-end Development</h3>
              <div className="experience--content">
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>HTML</h4>
                    <small className="text-shadow">Experienced</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>CSS</h4>
                    <small className="text-shadow">Experienced</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>SASS</h4>
                    <small className="text-shadow">Experienced</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>JavaScript</h4>
                    <small className="text-shadow">Experienced</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>TypeScript</h4>
                    <small className="text-shadow">Experienced</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>React</h4>
                    <small className="text-shadow">Experienced</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>Bootstrap</h4>
                    <small className="text-shadow">Experienced</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>TailwindCSS</h4>
                    <small className="text-shadow">Experienced</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>Chakra UI</h4>
                    <small className="text-shadow">Experienced</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>Material UI</h4>
                    <small className="text-shadow">Experienced</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>React Query</h4>
                    <small className="text-shadow">Intermediate</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>Playwright</h4>
                    <small className="text-shadow">Experienced</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>React Testing Library</h4>
                    <small className="text-shadow">Intermediate</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>Vite, Webpack</h4>
                    <small className="text-shadow">Experienced</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>Storybook</h4>
                    <small className="text-shadow">Experienced</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>Angular</h4>
                    <small className="text-shadow">Basic</small>
                  </div>
                </article>
              </div>
            </motion.div>
            <motion.div
              initial={{
                x: -200,
                opacity: 0,
              }}
              whileInView={{
                x: 0,
                opacity: 1,
              }}
              transition={{
                duration: 1.5,
              }}
              viewport={{
                once: true,
              }}
              className="experience--backend"
            >
              <h3>Back-end Development</h3>
              <div className="experience--content">
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>Node.js</h4>
                    <small className="text-shadow">Experienced</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>Express</h4>
                    <small className="text-shadow">Intermediate</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>REST API + HTTP</h4>
                    <small className="text-shadow">Experienced</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>GraphQL</h4>
                    <small className="text-shadow">Basic</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>MongoDB</h4>
                    <small className="text-shadow">Experienced</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>PostegreSQL</h4>
                    <small className="text-shadow">Basic</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>PHP</h4>
                    <small className="text-shadow">Basic</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>Laravel</h4>
                    <small className="text-shadow">Basic</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>WordPress</h4>
                    <small className="text-shadow">Intermediate</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>Python</h4>
                    <small className="text-shadow">Basic</small>
                  </div>
                </article>
              </div>
            </motion.div>
            <motion.div
              initial={{
                x: -200,
                opacity: 0,
              }}
              whileInView={{
                x: 0,
                opacity: 1,
              }}
              transition={{
                duration: 1.5,
              }}
              viewport={{
                once: true,
              }}
              className="experience--general"
            >
              <h3>General/Full-stack Skills</h3>
              <div className="experience--content">
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>Git</h4>
                    <small className="text-shadow">Experienced</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>Eslint+Prettier</h4>
                    <small className="text-shadow">Experienced</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>Next.js</h4>
                    <small className="text-shadow">Intermediate</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>Postman</h4>
                    <small className="text-shadow">Intermediate</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>Atomic design</h4>
                    <small className="text-shadow">Experienced</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>Trunk based development</h4>
                    <small className="text-shadow">Experienced</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>Docker</h4>
                    <small className="text-shadow">Basic</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>Agile/SCRUM</h4>
                    <small className="text-shadow">Experienced</small>
                  </div>
                </article>
                <article className="experience--details">
                  <BsPatchCheckFill className="experience--details-icon" />
                  <div>
                    <h4>Figma/Adobe Photoshop</h4>
                    <small className="text-shadow">Experienced</small>
                  </div>
                </article>
              </div>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default Experience;
