import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {BsTelegram} from 'react-icons/bs'
import {BsGithub} from 'react-icons/bs'
import {FiMail} from 'react-icons/fi'

const HeaderSocials = () => {
  return (
    <div className="header--socials">
        <a href="https://t.me/staszek_dev" target="_blank" rel="noreferrer"><BsTelegram /></a>
        <a href="https://github.com/mozger3714" target="_blank" rel="noreferrer"><BsGithub /></a>
        <a href="mailto:mr.smeett@gmail.com" target="_blank" rel="noreferrer"><FiMail /></a>
        <a href="https://www.linkedin.com/in/stanislav-kyrylchuk" target="_blank" rel="noreferrer"><BsLinkedin /></a>
    </div>
  )
}

export default HeaderSocials