import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

import {FiMail} from 'react-icons/fi'
import {BsTelegram} from 'react-icons/bs'
import './contact.scss'


const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_5d2h1mo', 'template_3ymlv2a', form.current, 'COYGvN9kyOyoIUBa4')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  };
  return (
    <section id="contact">
      <div className="section-component">
        <div className="container">
          <h5>Get In Touch</h5>
          <h2>Contact Me</h2>
          <div className="container--contact">
            <div className="contact--options">
              <article className="contact--option">
                <FiMail className='contact--option-icon' />
                <h4>Email</h4>
                <h5>mr.smeett@gmail.com</h5>                
                <a href="mailto:mr.smeett@gmail.com" target="_blank" rel="noreferrer">Send an email</a>
              </article>
              <article className="contact--option">
                <BsTelegram className='contact--option-icon' />
                <h4>Telegram</h4>
                <h5>@staszek_dev</h5>                
                <a href="https://t.me/staszek_dev" target="_blank" rel="noreferrer">Send a message</a>
              </article>             
            </div>
            <div className="contact--form">
              <form ref={form} onSubmit={sendEmail}>
                <input type="text" name='name' placeholder='Name / Company name' required />
                <input type="email" name="email" placeholder='Your Email' required />
                <textarea name="message" rows="7" placeholder='Your message....' required></textarea>
                <button type='submit' className='btn btn-primary'>Send form</button>
              </form>
            </div>            
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact