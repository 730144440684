import React, { useState } from 'react'
import './nav.scss'
import {AiOutlineHome} from 'react-icons/ai'
import {AiOutlineUser} from 'react-icons/ai'
import {FaListUl} from 'react-icons/fa'
import {MdContactSupport} from 'react-icons/md'


const Nav = () => {
    const [activeNav, setActiveNav] = useState('#')    
  return (
    <nav>
        <a href="#" className={activeNav === '#' ? 'active' : ''} onClick={()=> setActiveNav('#')}><AiOutlineHome /></a>
        <a href="#about" className={activeNav === '#about' ? 'active' : ''} onClick={()=> setActiveNav('#about')}><AiOutlineUser /></a>
        <a href="#experience" className={activeNav === '#experience' ? 'active' : ''} onClick={()=> setActiveNav('#experience')}><FaListUl /></a>
        <a href="#contact"  className={activeNav === '#contact' ? 'active' : ''} onClick={()=> setActiveNav('#contact')}><MdContactSupport /></a>
    </nav>
  )
}

export default Nav