import React from 'react'
import { motion } from "framer-motion"
import {BiCheck} from 'react-icons/bi'

import './services.scss'

const Services = () => {
  return (
    <section id="services">
      <motion.div 
      initial={{opacity:0}}
      whileInView={{opacity: 1}}
      transition={{duration: 1.5}}    
      viewport={{
        once: true,
      }}
      className="section-component">
        <div className="container">
          <h5>Some custom apps I've created to practice</h5>
          <h2>React Example Apps</h2>
          <div className="container--services">
              <article className="service">
                <div className="service--head">
                  <h3>GIFs search app</h3>
                </div>
                <ul className="service--list">
                  <li>
                    <BiCheck className='service--list-icon'/>
                    <p>Infinite scrolling</p>
                  </li>
                  <li>
                    <BiCheck className='service--list-icon'/>
                    <p>Api data fetch</p>
                  </li>
                  <li>
                    <BiCheck className='service--list-icon'/>
                    <p>Written in Typescript</p>
                  </li>
                </ul>
                <div className="service--link">
                  <a href='https://github.com/mozger3714/giff-search-app' className='btn'>Github</a>
                </div>
              </article>
              <article className="service">
                <div className="service--head">
                  <h3>Currency converter</h3>
                </div>
                <ul className="service--list">
                  <li>
                    <BiCheck className='service--list-icon'/>
                    <p>No token is required for API</p>
                  </li>
                  <li>
                    <BiCheck className='service--list-icon'/>
                    <p>Easy to set-up wanted currency</p>
                  </li>
                  <li>
                    <BiCheck className='service--list-icon'/>
                    <p>Written in React</p>
                  </li> 
                </ul>
                <div className="service--link">
                  <a href='https://github.com/mozger3714/currency-converter' className='btn'>Github</a>
                </div>
              </article>            
              <article className="service">
                <div className="service--head">
                  <h3>Quizz game</h3>
                </div>
                <ul className="service--list">
                  <li>
                    <BiCheck className='service--list-icon'/>
                    <p>Progress-bar quizz app</p>
                  </li>
                  <li>
                    <BiCheck className='service--list-icon'/>
                    <p>Written in React</p>
                  </li>
                  <li>
                    <BiCheck className='service--list-icon'/>
                    <p>Easy set-up</p>
                  </li>   
                  
                </ul>
                <div className="service--link">
                  <a href='https://github.com/mozger3714/quizz-game' className='btn'>Github</a>
                </div>
               
              </article>
          </div>
        </div>
      </motion.div>
    </section>
  )
}

export default Services